import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntersection } from "react-use";
import { Section, Container, ColContent, ColImage, Video } from "./styles";
import ContentSection from "components/ContentSection";

function MainSection({
  id,
  spacing,
  title,
  text,
  items,
  image,
  reversed,
  color,
  children,
  className,
  videoYoutube,
  video,
}) {
  const [showControls, setShowControls] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const sectionRef = useRef(null);

  // Verifica se a seção está visível na tela
  const intersection = useIntersection(sectionRef, {
    root: null, // viewport
    rootMargin: "200px", // Carrega um pouco antes de entrar na tela
    threshold: 0.1, // Quando 10% da seção estiver visível
  });

  // Ativa o carregamento do vídeo quando a seção estiver visível
  if (intersection && intersection.isIntersecting && !loadVideo) {
    setLoadVideo(true);
  }

  return (
    <Section
      ref={sectionRef}
      className={className}
      id={id}
      color={color}
      spacing={spacing}
    >
      <Container reversed={reversed}>
        <ColContent>
          <ContentSection title={title} text={text} items={items}>
            {children}
          </ContentSection>
        </ColContent>
        <ColImage reversed={reversed}>
          {video ? (
            <Video
              onMouseEnter={() => setShowControls(true)}
              onMouseLeave={() => setShowControls(false)}
            >
              {loadVideo && (
                <video
                  controls={showControls}
                  muted={false}
                  width="100%"
                  poster={video.poster}
                >
                  <source src={video.src} type={video.type} />
                  Seu navegador não suporta o elemento de vídeo.
                </video>
              )}
            </Video>
          ) : videoYoutube ? (
            <div style={{ width: "80%", alignSelf: "flex-end" }}>
              <Video>
                {loadVideo && (
                  <iframe
                    width="100%"
                    height="100%"
                    src={`${videoYoutube}?controls=0&showinfo=0&rel=0`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </Video>
            </div>
          ) : (
            image
          )}
        </ColImage>
      </Container>
    </Section>
  );
}

MainSection.defaultProps = {
  id: null,
  spacing: [60, 20],
  title: null,
  text: null,
  color: null,
  items: null,
  video: null,
  videoYoutube: null,
};

MainSection.propTypes = {
  id: PropTypes.string,
  spacing: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    poster: PropTypes.string,
  }),
  videoYoutube: PropTypes.string,
};

export default MainSection;
